.AthelasButton:hover {
  opacity: 0.7;
  cursor: pointer;
}

.AthelasButton:disabled,
.AthelasButton[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
