.mainContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.loginFormGroup {
  min-width: 500px;
  margin-bottom: 0px;
}

.LeftImageContainer {
  width: 40%;
  background-color: #ffe4ea;
  display: grid;
  place-items: center;
}

.LeftImageContainer svg {
  width: 70%;
  height: 100vh;
}

.RightLoginContainer {
  width: 100%;
  display: grid;
  place-items: center;
}

.SignInHeader {
  font-family: "Montserrat-Bold";
  margin-bottom: 20px;
}

.LogoSVG {
  height: 48px;
  margin: 20px auto 60px auto;
  width: 100%;
}
