body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  width: 100vw;
  display: grid;
  /*left side is nav bar*/
  /*right side is the main content*/
  grid-template-columns: 252px auto;

  font-family: Montserrat-Regular;
  font-style: normal;
}

@media only screen and (max-width: 1024px) {
  #root {
    grid-template-columns: 1fr;
  }
}

/*tweak all react visual grid*/
html {
  --scrollbarBG: #edf6ff;
  --thumbBG: #42a1f8;
  box-sizing: border-box;
}

.ReactVirtualized__Grid::-webkit-scrollbar {
  width: 8px;
}
.ReactVirtualized__Grid {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.ReactVirtualized__Grid::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.ReactVirtualized__Grid::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 32px;
}

@font-face {
  font-family: "Montserrat-Black";
  src: url(./fonts/Montserrat-Black.ttf);
}
@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url(./fonts/Montserrat-BlackItalic.ttf);
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(./fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url(./fonts/Montserrat-BoldItalic.ttf);
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url(./fonts/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url(./fonts/Montserrat-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url(./fonts/Montserrat-ExtraLight.ttf);
}
@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url(./fonts/Montserrat-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url(./fonts/Montserrat-Italic.ttf);
}
@font-face {
  font-family: "Montserrat-Light";
  src: url(./fonts/Montserrat-Light.ttf);
}
@font-face {
  font-family: "Montserrat-LightItalic";
  src: url(./fonts/Montserrat-LightItalic.ttf);
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(./fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url(./fonts/Montserrat-MediumItalic.ttf);
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url(./fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(./fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url(./fonts/Montserrat-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url(./fonts/Montserrat-Thin.ttf);
}
@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url(./fonts/Montserrat-ThinItalic.ttf);
}
