.LogoLoadingWrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.HeaderTitle {
  margin: auto auto auto 0;

  font-family: Montserrat-Bold;
  font-size: 24px;
  line-height: 125%;

  color: #191919;
  user-select: none;
}
