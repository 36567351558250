.Body {
  font-family: "Montserrat-Medium";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Loader {
  color: #fc5378;
}
