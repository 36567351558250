.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;

  font-size: 16px;
  line-height: 24px;

  color: #9ba0ad;
  text-transform: none;
  border: none;

  text-align: center;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;

  background:
        /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px;
  /* if linear-gradient, we need to resize it */

  padding: 16px 0 16px 0;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 85%;
  margin: 10px auto 0 auto;
  padding: 24px 24px 32px 24px;
  background: #ffffff;
}

.Button {
  width: 100px;
  height: 15px;
  background: #df5858;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
}
