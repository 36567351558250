.DesktopNavContainer {
  position: sticky;
  overflow: auto;
  height: 100vh;
  width: 252px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  user-select: none;
}

.MobileNavContainerShow {
  position: absolute;
  background: white;
  height: 100vh;
  z-index: 99;
  width: 252px;
  user-select: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

  transition: 0.5s;
  transform: translateX(0);
}

.MobileNavContainerHide {
  position: absolute;
  background: white;
  height: 100vh;
  z-index: 99;
  width: 252px;
  user-select: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

  transition: 0.5s;
  transform: translateX(-252px);
}

.MobileNavBackground_Show {
  background: grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 98;

  opacity: 0.2;
  transition: 0.5s;
}

.MobileNavBackground_Hide {
  background: grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -98;

  opacity: 0;
  transition: 0.5s;
}

/*section 1*/
.navHeader {
  padding: 18px 40px 0px 40px;
}

/*section 1*/
.navMain {
}

/*section 3*/
.navFooter {
  position: absolute;
  bottom: 0;
  padding: 40px;
}

.LogoDivider {
  margin-top: 30px;
}

.navList {
  list-style-type: none;
}

.navlink {
  font-size: 90%;
}

.navLinks li a.active {
  color: #42a1f8;
  font-family: "Montserrat-Bold";
  fill: #42a1f8;
  font-size: 90%;

  background: linear-gradient(
    270deg,
    #edf2fc 1.37%,
    rgba(246, 249, 255, 0) 100%
  );
  border-radius: 40px;
}

.navLinks li a {
  color: black;
  text-decoration: none; /* no underline */
  width: 100%;
  display: block;
  padding: 10px 0;
}

.navLinks li a span {
  padding: 1px 16px 1px 28px;
}

.navLinks {
  padding-right: 16px;
}

.navLinks :first-child {
  margin-left: 0;
}
