.FilteredStatsHeader {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 400px));
  gap: 1rem;
  /* gap: 2rem;
  grid-template-columns: 1fr 0.5px 1fr 0.5px 1fr 0.5px 1fr; */
  justify-content: space-between;
  margin-bottom: 15px;
}

.FilterStatDataPill {
  /* border-radius: 32px; */
  /* border-right: 2px solid #f2f6fc; */
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* .FilterStatDataPill:last-child {
  border-radius: 32px;
  border-right: none;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
} */

.FilterStatDataValue {
  font-family: "Montserrat-Bold";
  font-size: 28px;
  line-height: 21px;
  color: #565656;
  margin-top: 12px;
}

.FilterStatDataText {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #565656;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #565656;
  cursor: pointer;
  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px; /* if linear-gradient, we need to resize it */
  padding: 0px;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  border: 1.98805px solid #ffffff;
  border-radius: 2px;
  padding: 0px 0px 0px 0px;

  font-size: 14px;
  line-height: 12px;

  color: #92959f;
  text-transform: none;
  border: none;

  text-align: center;
  background-color: rgb(225, 234, 247);
}

.TableCardContainer {
  /* box-shadow: 16px 16px 70px rgba(216, 222, 228, 1); */
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 500px;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 45px -45px rgba(93, 120, 160, 0.15);
}

.ClaimsOverviewContainer {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  flex-direction: column;
}

.FilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: 12px;
  align-items: center;
}

.FilterCard {
  background: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 0.5px solid #e0e0e0;
  box-shadow: 0px 2px 45px rgba(93, 120, 160, 0.15);
}

.TableContainer {
  height: 80%;
  width: 100%;
}

.ClaimStatusText {
  font-family: Montserrat-Bold;
  border-radius: 6px;
  padding: 6px 40px;
  display: inline-block;
}

.Submitted {
  color: #42a1f8;
  background-color: rgba(66, 161, 248, 0.1);
}

.Rejected {
  color: #fc5378;
  background-color: rgba(252, 83, 120, 0.12);
}

.Approved {
  color: #01796f;
  background-color: rgba(66, 248, 193, 0.15);
}
.ProviderApprovalPending {
  color: #ffa726;
  background-color: rgba(255, 167, 38, 0.1);
}
.ProviderApproved {
  color: #388e3c;
  background-color: rgba(56, 142, 60, 0.1);
}
.ProviderRejected {
  color: #d32f2f;
  background-color: rgba(211, 47, 47, 0.1);
}

.LoaderContainer {
  display: grid;
  place-items: center;
  height: 80%;
}

.SummaryCard {
  box-shadow: 0px 0px 16px 2px rgba(93, 120, 160, 0.15);
  border-radius: 6px;
  min-height: 289px;
  display: grid;
  align-content: space-between;
  padding: 36px;
}

.ClaimSummaryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  text-align: center;
  margin: 15px 20px 30px 20px;
  padding: 40px 50px 50px 50px;
}

.SummaryCardTitle {
  text-align: left;
  margin-bottom: 10px;
}

.SummaryCardTitleText {
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  margin-right: 16px;
  color: #565656;
}

.SummaryCardInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid #e8effa;
  border-radius: 2px;
  padding: 12px;
  font-size: 16px;
  align-items: center;
  color: #565656;
  margin-bottom: 12px;
  /* justify-items: center; */
}

.SummaryCardInfoText {
  font-family: "Montserrat-Bold";
  border-right: 2px solid #e8effa;
  color: #565656;
}

.ClaimStatusTextDetail {
  margin-left: 25px;
  margin-right: 5px;
}

.AllTimeStatsHeader {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 400px));
  gap: 1rem;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 25px;
}

.SingleStatDataPill {
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(45, 93, 121, 0.1);
  padding-top: 25px;
  padding-right: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SingleStatTextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
}

.SingleStatDataText {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #565656;
  margin-bottom: 15px;
}

.SingleStatDataValue {
  font-family: "Montserrat-Bold";
  font-size: 40px;
  line-height: 21px;
}

.divider {
  width: 0.5px;
  margin: 15px 15px;
  background: #e0e0e0;
}

.filterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CSVDownloadButton {
  background: #ffffff;
  height: 48px;
  padding: 0 5px 0 5px;
  color: #42a1f8;
  background-color: rgba(66, 161, 248, 0.1);
  border: none;
  border-radius: 6px;
}

.ClaimsDownloadButtonText {
  color: #4b93c8;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.ApproveSection {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 40px;
  gap: 12px;
}
