.logo {
  width: 40%;
  margin-right: 60%;
  margin-bottom: 36px;
}

.password-reset-image {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 24px;
  margin-top: 24px;
}

.email_input {
  background: #f6f9fd;
  border-radius: 8px;
  max-width: 500px;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 100%;
  max-width: 500px;
  border: solid 1px rgba(155, 155, 155, 0.2);
  margin-bottom: 16px;
}

.errorMessage {
  font-size: 80%;
  color: red;
  font-weight: bold;
}

.label {
  font-size: 80%;
  color: rgb(133, 133, 133);
  margin-bottom: 4px;
  margin-top: 16px;
}

.container {
  background-color: white;
  width: 380px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  margin: 24px auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: left;
}
