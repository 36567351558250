.AgreementContainer {
  display: grid;
  grid-template-rows: 120px 1fr;
  gap: 20px;
  width: 100vw;
  background-color: #f9fbff;
}

.CommonContainer {
  display: grid;
  grid-template-rows: 140px 120px 1fr;
  width: 100vw;
  background-color: #f9fbff;
  text-align: center;
}

.StepsHeaderContainer {
  margin: 0 84px;
  background: #ffffff;
  border-radius: 0px 0px 32px 32px;
  display: grid;
  grid-template-rows: 0 1fr 1fr 20px;
}

.StepsGrid {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}

.StepsGrid p {
  font-family: "Montserrat-Bold";
}

.StepIconGrid {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}

.StepIconGrid div {
  margin: auto;
}

.StepsGrid div {
  margin: auto auto 0 auto;
  text-align: center;
}

.StepsGrid div p {
  font-size: 14px;
  margin-bottom: 0;
}

.DashLine {
  width: 120px;
  height: 0px;
  border: 1px dashed #e2e5ea;
  transform: rotate(180deg);
}

.BlackSolidLine {
  width: 100px;
  height: 0px;
  border: 1px solid #e2e5ea;
  transform: rotate(180deg);
}

.PinkSolidLine {
  width: 100px;
  height: 0px;
  border: 1px solid #fc5378;
  transform: rotate(180deg);
}

.WhiteCircle {
  width: 20px;
  height: 20px;
  border: 1.5px solid #5d6f7e;
  border-radius: 50%;
  margin: auto;
}

.RedDot {
  width: 20px;
  height: 20px;
  background: #fc5378;
  border: 2px solid #fc5378;
  border-radius: 50%;
  margin: auto;
}

.MiddleInstructionSection {
  margin: auto;
  font-family: Montserrat-Bold;
  font-size: 24px;
}

.MainContainer {
  margin: 0 84px 84px 84px;
  background: #ffffff;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  display: grid;
  grid-template-rows: 1fr 100px;
  font-family: Montserrat-Regular;
}

.AgreementMainContainer {
  margin: 0 84px 84px 84px;
  background: #ffffff;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  display: grid;
  grid-template-rows: 1fr 200px;
  font-family: Montserrat-Regular;
}

.Main {
  border-bottom: 1px solid#F5F5F5;
}

.AgreementMain {
  border-bottom: 1px solid#F5F5F5;
  max-height: calc(150vh - 250px);
  padding-top: 40px;
  overflow: auto;
}

.CongrsContainer {
  height: 100%;
  display: grid;
  justify-content: center;
  margin: auto;
  padding-top: 40px;
}

.CongrsContainer svg {
  height: 100%;
}

.ContentFooter {
  margin: auto 80px;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.ContentFooterRight {
  text-align: right;
  margin-top: 10px;
}

.ContentFooterLeft {
  text-align: left;
  margin-top: 10px;
}

.Agreement {
  overflow: auto;
}

.LeftTop {
  grid-area: LeftTop;
  text-align: left;
}

.RightTop {
  grid-area: RightTop;
}

.LeftBottom {
  grid-area: LeftBottom;
  text-align: left;
  display: grid;
}

.RightBottom {
  grid-area: RightBottom;
  text-align: left;
}

.RightBottomWrapper {
  border-left: 1px solid #e1e3e8;
  padding: 24px;
}

.PasswordContent {
  display: grid;
  grid-template-areas: "LeftTop RightTop" "LeftBottom RightBottom";
  padding: 40px 80px;
  gap: 40px;
}

.SmallRedDot {
  width: 14px;
  height: 14px;
  background: #fc5378;
  border-radius: 50%;
  display: inline-block;
  margin: auto 16px auto auto;
}

.SmallGreenDot {
  width: 14px;
  height: 14px;
  background: #0be4a3;
  border-radius: 50%;
  display: inline-block;
  margin: auto 16px auto auto;
}

.CheckListItem {
  margin: 4px 0;
  display: grid;
  grid-template-columns: 36px 1fr;
}

.PasswordStrengthText {
  color: #fc5378;
}

.BankAccountWrapper {
  padding: 0 15% 0 15%;
}

.CreditCardWrapper {
  padding: 0 80px;
  margin-bottom: 40px;
}

.CreditCardTitleSection {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.CreditCardTitle {
  text-align: left;
  font-family: Montserrat-Bold;
  font-size: 24px;
  display: grid;
  align-items: center;
}

.CreditCardBody {
  text-align: left;
  font-family: Montserrat-Regular;
  font-size: 16px;
  align-items: center;
  margin-bottom: 0;
}

.CreditCardLogos {
  text-align: right;
}

.CreditCardLogos img {
  height: 80px;
}

.CreditCardContent {
  margin-top: 40px;
  text-align: left;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.CreditCardInputGrid {
  display: grid;
  align-items: center;
}

.BASelect {
  padding: 10px 20px 10px 32px;
  background: #f6f9fd;
  border-radius: 42px;
  max-width: 500px;
  border: none;
  color: #151522;
  width: 80%;
  max-width: 500px;
}

.CCInput {
  background: #f6f9fd;
  border-radius: 42px;
  max-width: 500px;
  border: none;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 80%;
  max-width: 500px;
}

.CCInputLabel {
  color: #9ba0ad;
  padding-left: 32px;
}

.BankAccountContent {
  display: grid;
  text-align: left;
  align-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin-top: 20px;
  gap: 20px;
}

.AgreementWrapper {
  overflow: auto;
  padding: 0 80px 40px 80px;
  margin: auto;
  height: 100%;
}

.BackStepButton {
  background: #ffffff;
  border: 1px solid #c0c8d5;
  border-radius: 32px;
  display: inline-block;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  padding: 10px 40px;
}

.StepButton:focus {
  outline: 0;
}

.BackStepButton svg {
  margin-right: 8px;
}

.StepButton {
  background: #42a1f8;
  border: none;
  border-radius: 32px;
  padding: 10px 40px;
  color: white;
  display: inline-block;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  margin-bottom: 10px;
}

.StepButton:focus {
  outline: 0;
}

.DisabledStepButton {
  background: #a6d2ff;
  border: none;
  border-radius: 32px;
  padding: 10px 40px;
  color: white;
  display: inline-block;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
}

.PaymentTabs {
  position: relative;
  top: -32px;
  padding-left: 32px;
}

.PaymentTabs div {
  margin-right: 12px;
}

.PaymentTabs a {
  text-decoration: none;
  /* no underline */
}

.LoaderWrapper {
  display: grid;
  height: 100%;
  place-items: center;
}

.ActiveTab {
  padding: 0 8px 8px 8px;
  cursor: pointer;
  border-bottom: 4px solid #42a1f8;
}

.InactiveTab {
  padding: 0 8px 8px 8px;
  cursor: pointer;
  color: #828898;
}

.current {
  color: #fc5378;
}

.future {
  color: #5d6f7e;
}

.ReferralChild {
  text-align: center;
  padding: 15px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
}

.ReferralLabel {
  color: #9ba0ad;
  place-self: center;
}

.ReferralHeader {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 100px;
}

.ReferralInput {
  background: #f6f9fd;
  border-radius: 42px;
  max-width: 500px;
  border: none;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 80%;
  margin-left: 10px;
}

.ReferralHeaderTitle {
  margin: auto;
  font-family: Montserrat-Bold;
  font-size: 18px;
}

.providerInformationLabel {
  color: #9ba0ad;
  text-align: left;
  width: 400px;
}

.providerInformationInput {
  background: #f6f9fd;
  border-radius: 42px;
  border: none;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 500px;
  margin-bottom: 24px;
}
