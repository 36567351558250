.TodosContainer {
  padding: 24px;
  margin-bottom: 20px;
}

.TodoCard {
  padding: 16px;
  border: solid 2px rgba(203, 226, 235, 0.623);
  cursor: pointer;
  margin-bottom: 12px;
  background: white;
  border-radius: 16px;
  grid-template-columns: 10fr 2fr;
}

.TodoCardTitle {
  font-family: "Montserrat-Bold";
  font-size: 1rem;
}

.TodoCardSubtitle {
  font-size: 90%;
}

.SectionTitle {
  font-family: Montserrat-Regular;
  margin-bottom: 16px;
}
