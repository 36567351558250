.MainContainer {
  height: 100vh;
  width: 100vw;
  background-color: #f9fbff;
  text-align: left;
  padding: 64px;
}

.MainContent {
  padding: 64px 128px;
  background: #ffffff;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  font-family: Montserrat-Regular;
}

.Footer {
  margin-top: 40px;
}

.PasswordInput {
  background: #f6f9fd;
  border-radius: 42px;
  max-width: 500px;
  border: none;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 80%;
  max-width: 500px;
}

.UsernameInput {
  background: #f6f9fd;
  border-radius: 42px;
  max-width: 500px;
  border: none;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 80%;
  max-width: 500px;
  cursor: no-drop;
}

.PWInputLabel {
  color: #9ba0ad;
  padding-left: 32px;
}

.LeftTop {
  grid-area: LeftTop;
  text-align: left;
}

.RightTop {
  grid-area: RightTop;
}

.LeftBottom {
  grid-area: LeftBottom;
  text-align: left;
  display: grid;
}

.RightBottom {
  grid-area: RightBottom;
  text-align: left;
}

.RightBottomWrapper {
  border-left: 1px solid #e1e3e8;
  padding: 24px;
}

.PasswordContent {
  display: grid;
  grid-template-areas: "LeftTop RightTop" "LeftBottom RightBottom";
  padding: 40px 0;
  gap: 40px;
}

.SmallRedDot {
  width: 14px;
  height: 14px;
  background: #fc5378;
  border-radius: 50%;
  display: inline-block;
  margin: auto 16px auto auto;
}

.SmallGreenDot {
  width: 14px;
  height: 14px;
  background: #0be4a3;
  border-radius: 50%;
  display: inline-block;
  margin: auto 16px auto auto;
}

.ConfirmButton {
  background: #42a1f8;
  border: none;
  border-radius: 32px;
  padding: 10px 40px;
  color: white;
  display: inline-block;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  width: 480px;
}

.ConfirmButton:focus {
  outline: 0;
}

.DisabledConfirmButton {
  background: #a6d2ff;
  border: none;
  border-radius: 32px;
  padding: 10px 40px;
  color: white;
  display: inline-block;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  width: 480px;
}

.LogoSection {
  padding-left: 16px;
  margin-bottom: 32px;
}

.Instruction {
  padding-left: 16px;
}

.LoaderWrapper {
  display: grid;
  height: 100%;
  place-items: center;
}
