.LoaderContainer {
  display: grid;
  place-items: center;
  height: 80%;
}

.ContentContainer {
  overflow: auto;
  padding: 16px;
}

.CCMContainer {
  height: 100%;
}

.CCMTable {
  height: 82%;
}

.CCMFooter {
  height: 85%;
  margin-top: 10px;
  margin-left: 10px;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 90%;
  margin: 0 auto;
  padding: 24px;
  background: #ffffff;
}

.TableHeader {
  align-items: center;
  margin-bottom: 12px;
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 20px;

  font-size: 16px;
  line-height: 24px;

  color: #9ba0ad;
  text-transform: none;
  border: none;

  text-align: center;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;

  background:
    /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px;
  /* if linear-gradient, we need to resize it */

  padding: 16px 0 16px 20px;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.CCMStatusText {
  font-family: Montserrat-Bold;
  border-radius: 20px;
  padding: 8px 20px;
  display: inline-block;
}

.PATIENT_INTAKE_FORM {
  color: #1919bb;
  background-color: rgba(81, 66, 248, 0.15);
}

.APPROVED_CARE_PLAN {
  color: #139444;
  background-color: rgba(45, 183, 54, 0.15);
}

.UNAPPROVED_CARE_PLAN {
  color: #efce16;
  background-color: rgba(244, 203, 40, 0.15);
}

.FormContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  text-align: center;
  margin: 15px 20px 30px 20px;
  padding: 40px 50px 50px 50px;
}

.FormCard {
  box-shadow: 0px 0px 16px 2px rgba(93, 120, 160, 0.15);
  border-radius: 6px;
  min-height: 289px;
  display: grid;
  align-content: space-between;
  padding: 36px;
  background-color: white;
}

.FormCardTitle {
  text-align: left;
  margin-bottom: 10px;
}

.FormCardTitleText {
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  margin-right: 16px;
  color: #565656;
}

.FieldCardInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid #e8effa;
  border-radius: 2px;
  padding: 12px;
  font-size: 16px;
  align-items: center;
  color: #565656;
  margin-bottom: 12px;
}

.FieldCardInfoText {
  font-family: "Montserrat-Bold";
  border-right: 2px solid #e8effa;
  color: #565656;
}

.ApproveFormCard {
  box-shadow: 0px 0px 16px 2px rgba(93, 120, 160, 0.15);
  border-radius: 6px;
  height: max-content;
  display: grid;
  align-content: flex-start;
  padding: 36px;
  background-color: white;
  text-align: left;
}
