.mainContent {
  display: grid;
  grid-template-rows: 80px auto;
  overflow: auto;
  background: #f9fbff;
}

.FilterSelected {
  background: #e8effa;
  border-radius: 32px;
  padding: 8px 20px;
  user-select: none;
  cursor: pointer;
  font-family: Montserrat-SemiBold;
  display: grid;
  grid-template-columns: 32px 1fr;
  justify-content: center;
}

.FilterUnselected {
  padding: 8px 20px;
  user-select: none;
  cursor: pointer;
  font-family: Montserrat-SemiBold;
  display: grid;
  grid-template-columns: 32px 1fr;
  justify-content: center;
}

.FilterUnselected svg {
  visibility: hidden;
}

.SelectPlaceHolder {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  height: 48px;
  padding: 0 0 0 16px;
  color: grey;
}

.SelectInput {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  height: 48px;
  padding: 0 0 0 16px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 80px;
}

select option:disabled {
  color: grey;
  font-weight: bold;
}

.select-form-group:after {
  content: url("select-arrow.svg");
  pointer-events: none;
  position: relative;
  left: -32px;
}

/* react_dates_overrides */

.DateRangePicker {
  margin-left: 12px;
  border: 2px solid #e8effa;
  border-radius: 32px;
  padding: 0 0 0 24px;
  font-family: Montserrat-Regular;
}

.DateRangePickerInput {
  background: none !important;
}

.DateInput {
  margin: 0 !important;
  background: none !important;
  width: 110px !important;
}

.DateInput_input {
  font-size: 16px !important;
  background: none !important;
}

.DateInput_input__focused {
  background: none !important;
}

.ClaimsOverviewSelectInput {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 5px;
  height: 48px;
  padding: 0 5px 0 5px;
  font-size: 13px;
}

.ClaimsOverviewSelectPlaceHolder {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 5px;
  height: 48px;
  padding: 0 5px 0 5px;
  color: grey;
  font-size: 13px;
}
