.HeaderWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 0 20px;
  justify-items: end;
  align-items: center;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.HeaderTitle {
  margin: auto auto auto 0;
  justify-self: start;

  font-family: Montserrat-Bold;
  font-size: 26px;
  line-height: 110%;

  color: #191919;
  user-select: none;
}

.iFrameHeaderTitle {
  margin: auto auto auto 12px;
  padding-left: 12px;
  justify-self: start;
  border-left: 1px solid #191919;

  font-family: Montserrat-Bold;
  font-size: 26px;
  line-height: 32px;

  color: #191919;
  user-select: none;
}

.HeaderImpersonate {
  width: 100%;
}

.Avatar {
  background: red;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.HeaderLeft {
  display: flex;
  width: 100%;
  padding-left: 20px;
}

.HeaderLeftWithBackButton {
  display: flex;
  width: 100%;
}

.BackButtonWrapper {
  border: 2px solid #e8effa;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: grid;
  place-items: center;
  margin: auto 12px auto 0;

  cursor: pointer;
  padding: 8px;
}

.ForwardButtonWrapper {
  border: 2px solid #e8effa;
  border-radius: 500px;
  transform: scale(-1, 1);
  margin: auto 0 auto 8px;
  height: 40px;
  width: 40px;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.HamburgerButtonWrapper {
  height: 24px;
  width: 24px;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin: auto 12px auto 0;
}

.ReferralButton {
  display: inline-block;
  margin-right: 20px;
}

.HeaderDropdown {
  display: inline-block;
}

.ReferralModal {
  min-width: 90%;
}

.RCMReferralModal {
  min-width: 800px;
}

.GoToAthelasWrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  border: 2px solid #e8effa;
  border-radius: 500px;
  transition: 0.2s;
}

.GoToAthelasWrapper:hover {
  border-color: #fc5378 !important;
  background-color: #e8effa;
}

.GoToAthelasWrapper:hover .ForwardButtonWrapper {
  background-color: #fc5378 !important;
}

.OnePagerImage {
  max-height: 500px;
}
