/* remove number spinner on the right side of input element */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.PaginationContainer {
  font-family: "Montserrat-Bold";
  user-select: none;
  /* Standard syntax */
  font-size: 1.2rem;
}

.PageButton {
  cursor: pointer;
  margin: 0 5px;
}
