.pill-button {
  padding: 8px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 36px;
  margin-right: 36px;
  margin-top: 8px;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.pill-button:hover {
  opacity: 0.6;
  transition: all 0.5s ease-out;
}
