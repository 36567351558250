.Container {
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  max-width: 800px;
  margin: 20px;
}

.flexBox {
  flex-direction: row;
  display: flex;
}

.checkLabel {
  margin-left: 16px;
  margin-top: 4px;
}

.thresholdSetterContainer {
  margin-left: 48px;
  padding: 16px;
  border: solid 1px #eee;
  border-radius: 8px;
  margin-bottom: 16px;
}

.dropDown {
  width: 50%;
  /*webkit browsers */
  -moz-appearance: none;
  /*Firefox */
  appearance: none;
  /* modern browsers */
  border-radius: 0;
  -webkit-appearance: listbox !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.EmailButton {
  display: inline-block;
  margin-right: 20px;
}

.SearchSection {
  margin-bottom: 20px;
  min-width: 500px;
  display: flex;
  flex-direction: row;
}

.SearchBox {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  width: 70%;
  height: 40px;
  padding-left: 60px;
  margin-top: -3px;
  /* margin-bottom: 20px; */
  margin-right: 10px;
}

.InvalidEmail {
  color: red;
  margin-left: 5px;
}
