.SuperContainer {
  max-width: 1400px;
  padding-bottom: 60px;
}

.Container {
  flex-direction: row;
  display: flex;
}

.explanationDiv {
  margin: 24px;
  background-color: white;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  padding: 24px;
  font-family: Montserrat-Regular;
  width: 60%;
}

.composerDiv {
  margin: 24px;
  background-color: white;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  padding: 24px;
  font-family: Montserrat-Regular;
  width: 50%;
}

.PreviewContainer {
  margin: 24px;
  background-color: white;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  padding: 24px;
  font-family: Montserrat-Regular;
  width: 50%;
}

.enrollmentText {
  background-color: white;
  text-align: center;
  padding-bottom: 16px;
}

.currentDiv {
  display: none;
  margin: 24px;
  background-color: white;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  font-family: Montserrat-Regular;
  width: 25%;
  background-color: #e9e9e9;
}

.currentStatistic {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
  margin: 0;
  margin-top: -16px;
  background-color: white;
}

.currentDivTitle {
  background-color: #e9e9e9;
  border-top-left-radius: 32px;
  text-align: center;
  padding: 8px;
  font-weight: bold;
  border-top-right-radius: 32px;
}

.currentExplanation {
  font-size: 90%;
  padding: 24px;
  padding-top: 8px;
  background-color: #e9e9e9;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  font-weight: bold;
}

.potentialDiv {
  display: none;
  margin: 24px;
  background-color: white;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  font-family: Montserrat-Regular;
  width: 25%;
  background-color: #42a1f8;
}

.potentialStatistic {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
  margin: 0;
  margin-top: -16px;
  background-color: white;
  color: #42a1f8;
}

.potentialExplanation {
  font-size: 90%;
  padding: 24px;
  padding-top: 8px;
  background-color: #42a1f8;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  font-weight: bold;
  color: white;
}

.potentialDivTitle {
  border-top-left-radius: 32px;
  text-align: center;
  padding: 8px;
  font-weight: bold;
  border-top-right-radius: 32px;
  background-color: #42a1f8;
  color: white;
}

.patientSelectDiv {
  margin: 24px;
  background-color: white;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  padding: 24px;
  font-family: Montserrat-Regular;
  width: 96%;
  height: 80%;
  margin-bottom: 24px;
}

.blueBold {
  color: #42a1f8;
  font-weight: bold;
}

/* Table CSS */

.TableHeader {
  display: grid;
  align-items: center;
  margin-bottom: 12px;
}

.ContentContainer {
  overflow: auto;
  padding: 24px;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 100%;
  margin: 0 auto;
  padding: 24px;
  background: #ffffff;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;
  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px;
  /* if linear-gradient, we need to resize it */
  padding: 16px 0 16px 20px;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;
  font-size: 16px;
  line-height: 24px;
  color: #9ba0ad;
  text-transform: none;
  border: none;
  padding-left: 20px;
  text-align: center;
}

/* Preview CSS */
.messageBubble {
  background-color: #1084ff;
  color: white;
  font-size: 60%;
  font-weight: bold;
  margin-top: 25px;
  margin-left: 60px;
  margin-right: 45px;
  padding: 16px;
  border-radius: 16px;
  max-height: 180px;
  overflow-y: scroll;
}

.messageName {
  font-weight: bold;
  text-align: center;
  width: 320px;
  font-size: 80%;
  margin-top: 90px;
}

.messageFloatyDiv {
  width: 100%;
  height: 320px;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.98;
  position: static;
  float: left;
}

.iphoneMockup {
  width: 320px;
  margin-top: -320px;
  position: static;
  top: 0px;
  left: 0px;
}
