.ContentContainer {
  overflow: auto;
  padding: 24px;
}

.ClaimContainer {
  height: 100%;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 80%;
  margin: 0 auto;
  padding: 24px;
  background: #ffffff;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;
  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px;
  /* if linear-gradient, we need to resize it */
  padding: 16px 0 16px 20px;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;
  font-size: 16px;
  line-height: 24px;
  color: #9ba0ad;
  text-transform: none;
  border: none;
  padding-left: 20px;
  text-align: center;
}

.LoaderContainer {
  display: grid;
  place-items: center;
  height: 100%;
}

.SingleClaimContainer {
  padding: 16px 20px 0 20px;
  overflow: auto;
}

.Card {
  background: #ffffff;
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 16px;
  display: grid;
  padding: 24px;
  border: solid 2px rgba(203, 226, 235, 0.623);
}

.CurrencyValueText {
  font-size: 30px;
  font-family: "Montserrat-Bold";
  margin-right: 8px;
}

.CurrencyTypeText {
  font-size: 30px;
  color: #98a0b0;
  margin-right: 40px;
}

.CardSubTitle {
  color: #9ba0ad;
  margin-left: 12px;
}

.CardTitle {
  font-family: "Montserrat-Bold";
  margin: 64px auto 16px auto;
  font-size: 30px;
}

.TabsContainer {
  margin-top: 32px;
  padding: 16px 0;
  border: 2px solid #e8effa;
  border-radius: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
}

.Tab {
  border-right: 2px solid #e8effa;
  padding-left: 64px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
}

.LastTab {
  padding-left: 64px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
}

.CardInnerHeader {
  margin-bottom: 16px;
}

.Reimbursed {
  background: #e3fef6;
  border-radius: 20px;
  padding: 8px;
  color: #42a1f8;
  font-family: "Montserrat-SemiBold";
  width: 120px;
  margin: auto;
}

.Submitted {
  background: #fbebfd;
  border-radius: 20px;
  padding: 8px;
  color: #c441d9;
  font-family: "Montserrat-SemiBold";
  width: 120px;
  margin: auto;
}

.TableHeader {
  display: grid;
  align-items: center;
  margin-bottom: 12px;
}

.ClaimsTableContainer {
  height: 90%;
}

.FilterLabel {
  color: #828898;
}

.SearchSection {
  margin-bottom: 20px;
}

.SearchBox {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  width: 100%;
  height: 64px;
  padding-left: 60px;
  margin-top: -20px;
}

.SearchIcon {
  position: relative;
  top: 20px;
  margin-left: 24px;
}

.TimelineGrid {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin-bottom: 20px;
}

.TimelineStatusText {
  color: #9ba0ad;
  font-size: 16px;
  margin-bottom: 8px;
}

.TimelineTimeText {
  color: #151522;
  font-family: Montserrat-SemiBold;
}

.TimelineIcon {
  /* padding: 4px; */
}
