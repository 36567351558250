.flex-box-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin-bottom: 16px;
  max-width: 1200px;
}

.flex-box {
  flex-direction: row;
  display: flex;
  margin-top: 16px;
}

.cardiac-care-label {
  margin-left: 8px;
  color: #888;
}

.cardiac-care-statistic {
  font-size: 140%;
  margin-left: 8px;
  margin-top: -16px;
  font-weight: bold;
}

.stat-container {
  text-align: center;
  width: 33%;
}

.left-container {
  padding: 16px;
  width: 55%;
  height: 40px;
  margin-bottom: 48px;
}

.right-container {
  padding: 16px;
  width: 45%;
  height: 40px;
  margin-bottom: 48px;
}

.cardiac-care-result-container {
  padding: 16px;
  padding-top: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  background-color: white;
}

.explanation {
  font-size: 85%;
  margin-left: 8px;
  color: "#888";
}
