.nurse-notes-client-container {
  width: 100%;
  height: 90%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  display: flex;
}

.left-navigation-bar {
  background-color: #f8f8f8;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  min-width: 350px;
  width: 20%;
  height: 100%;
  padding: 16px;
}

.notes-scroll-list {
  height: 85%;
  overflow-y: scroll;
}

.search-bar {
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 20px;
  width: 100%;
  border-radius: 8px;
  border: none;
  background-color: #eeeeee;
  margin-bottom: 24px;
}

.nurse-notes-thumbnail-container {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 8px;
  margin-bottom: 16px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nurse-notes-thumbnail-container-active {
  background-color: #d9eaf0;
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 8px;
  margin-bottom: 16px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nurse-notes-thumbnail-container:hover,
.nurse-notes-thumbnail-container-active:hover {
  background-color: #213e47;
  color: white !important;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nurse-notes-thumbnail-email-style {
  font-weight: bold;
  margin-top: -16px;
}

.nurse-title {
  font-size: 70%;
  color: #aaa;
  width: 50%;
  margin-bottom: -16px;
}

.date {
  font-size: 70%;
  color: #aaa;
  width: 50%;
  text-align: right;
}

.escalation_ack_date {
  font-size: 70%;
  color: #000;
  font-weight: bold;
  width: 50%;
  text-align: right;
}

.nurse-notes-note {
  color: #888;
  font-size: 90%;
}

.nurse-notes-flex-box {
  display: flex;
  height: 16px;
  flex-direction: row;
  margin-top: 0px;
}

.notes-details {
  width: 100%;
  height: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.divider {
  width: 90%;
  margin: 32px;
}

.details-container {
  padding: 54px;
  padding-top: 24px;
  font-size: 110%;
  height: 100%;
  overflow-y: scroll;
}

.details-date {
  font-size: 120%;
  font-weight: lighter;
}

.detail-label {
  color: #888;
  font-size: 70%;
  margin-top: 36px;
}

.details-nurse-note {
  font-size: 110%;
  white-space: pre-wrap;
}

.details-content {
  max-width: 800px;
}

.escalated-note {
  color: rgb(207, 133, 22);
  font-weight: bold;
  font-size: 70%;
  border: solid 2px rgb(207, 133, 22);
  width: 150px;
  text-align: center;
  border-radius: 4px;
  background-color: rgb(253, 243, 215);
  padding: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.LoaderContainer {
  display: grid;
  place-items: center;
  height: 80%;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 85%;
  margin: 10px auto 0 auto;
  padding: 24px 24px 32px 24px;
  background: #ffffff;
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;

  font-size: 16px;
  line-height: 24px;

  color: #9ba0ad;
  text-transform: none;
  border: none;

  text-align: center;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;

  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px; /* if linear-gradient, we need to resize it */

  padding: 16px 0 16px 0;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.NurseNotesTableContainer {
  padding: 24px;
}

.NurseNotesTableFilters {
  display: flex;
  flex-direction: row;
  width: 0;
  column-gap: 16px;
  padding: 4px 4px;
  font-size: 16px;
}

.PaginationLeft {
  display: grid;
  justify-items: start;
  align-items: center;
  margin: 12px;
}

.PaginationSection {
  grid-template-columns: 1fr 1fr;
  display: grid;
  padding-bottom: 48px;
}
