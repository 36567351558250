.DocumentsContainer {
  padding: 20px;
}

.ContractContainer {
  margin-bottom: 32px;
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-left: 12px;
}

.AgreementContainer {
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  gap: 40px;
  margin-bottom: 32px;

  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-left: 12px;
}

.NoAgreementContainer {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-left: 12px;
  margin-bottom: 20px;
}

.ClaimsCollectionsCard {
  padding: 0 40px;
  background: #ffffff;
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 32px;
  display: grid;
}

.ClaimsCollectionsCard h2 {
  margin: 12px 0;
}

.DocumentTitleText {
  font-family: "Montserrat-Bold";
}

.ClaimCollectionDownloadTableContainer {
  height: 400px;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;
  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px;
  /* if linear-gradient, we need to resize it */
  padding: 16px 0 16px 20px;
  text-align: center;
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;
  font-size: 16px;
  line-height: 24px;
  color: #9ba0ad;
  text-transform: none;
  border: none;
  padding-left: 20px;
  text-align: center;
}

.SectionHeader {
  font-family: Montserrat-Bold;
  font-size: 26px;

  color: #191919;
  user-select: none;
  margin-left: 20px;
}

.DownloadButton {
  display: inline;
  background: #ecf6fe;
  border-radius: 22px;
  padding: 8px 20px;
  color: #42a1f8;
  border: none;

  font-family: Montserrat-Bold;
  margin-left: 16px;
}

.DisabledDownloadButton {
  display: inline;
  background: #e5e5e5;
  border-radius: 22px;
  padding: 8px 20px;
  color: white;
  border: none;

  font-family: Montserrat-Bold;
  margin-left: 16px;
  cursor: default !important;
  user-select: none;
}

.DocumentName {
  font-family: Montserrat-Bold;
}

.RowTab {
  margin: auto;
}

.RowDivider {
  border: 2px solid #e8effa;
}

.ClaimCollectionsContainer {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  gap: 20px;
}

.ClaimCollectionCard {
  background: #ffffff;
  box-shadow: 32px 32px 70px rgba(216, 222, 228, 0.3);
  border-radius: 32px;
  height: 300px;
  overflow: hidden;
}

.ClaimCollectionDate {
  font-family: Montserrat-Bold;
  font-size: 24px;
  margin-left: 20px;
}

.ClaimCollectionDivider {
  margin: 0 0 8px 0;
}

.NumberOfPatientsSection {
  font-size: 18px;
  font-family: Montserrat-Bold;
  margin: 8px auto 16px 20px;
  color: #828898;
}

.NumberOfPatientsNumber {
  color: #151522;
  margin: 0 8px;
}

.ClaimDownloadButton {
  display: block;
  background: #ecf6fe;
  border-radius: 22px;
  padding: 8px 48px;
  color: #42a1f8;
  border: none;

  font-family: Montserrat-Bold;
  margin: auto;
}

.CollectionIcon {
  max-height: 100px;
}

.ImageContainer {
  height: 150px;
  background: #bce39e;
  display: grid;
  align-items: center;
  justify-items: center;
}
