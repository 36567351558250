.Header {
  padding: 20px;
}

.PatientsTableContainer {
  padding: 24px;
}

.SummaryCardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .SummaryCardsContainer {
    grid-template-columns: 1fr;
  }
}

.SummaryAndTrendLineContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  gap: 2rem;
}

@media only screen and (max-width: 1024px) {
  .SummaryAndTrendLineContainer {
    grid-template-columns: 1fr;
  }
}

.SimpleSummaryCard {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 32px;
  align-content: space-between;
  padding: 36px;
}

.SummaryCard {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 32px;
  min-height: 289px;
  display: grid;
  align-content: space-between;
  padding: 36px;
}

.CardTestTitle {
  text-align: left;
}

.TestType {
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  margin-right: 16px;
}

.TestTypeSmall {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #828898;
}

.CardValueSimple {
  grid-template-columns: 1fr 1fr;
  display: flex;
  border: 2px solid #e8effa;
  border-radius: 49.7012px;
  flex-direction: row;
  padding: 18px;

  font-size: 16px;
}

.CardValue {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid #e8effa;
  border-radius: 49.7012px;
  padding: 18px;

  font-size: 16px;
}

.CardValueText {
  font-family: "Montserrat-Bold";
  border-right: 2px solid #e8effa;
}

.OnScheduleText {
  background: #e3fef6;
  border-radius: 40px;
  height: 60px;

  display: grid;
  place-items: center;

  font-weight: bold !important;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  color: #42a1f8;
}

.OffScheduleText {
  background: #ffeaef;
  border-radius: 40px;
  font-weight: bold !important;
  height: 60px;

  display: grid;
  place-items: center;

  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  color: #fc5378;
}

.HistoryTable {
  text-align: center;
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  color: #151522;
}

.SinglePatientContainer {
  overflow: auto;
  padding: 24px;
}

.SinglePatientContainer h2 {
  font-family: "Montserrat-Bold";
  font-size: 24px;

  color: #121212;
  margin-left: 8px;
}

.SinglePatientContainer h5 {
  font-family: Montserrat-Bold;
  font-size: 20px;
  line-height: 21px;

  padding-left: 8px;
  text-align: left;
}

.SearchIcon {
  position: relative;
  top: 20px;
  margin-left: 15px;
}

.SearchSection {
  margin-bottom: 20px;
  min-width: 500px;
  display: flex;
  flex-direction: row;
}

.SearchBox {
  background: #ffffff;
  border: 2px solid #e8effa;
  border-radius: 32px;
  min-width: 260px;
  width: 100%;
  height: 64px;
  padding-left: 50px;
  margin-top: -20px;
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;

  font-size: 16px;
  line-height: 24px;

  color: #9ba0ad;
  text-transform: none;
  border: none;

  text-align: center;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;

  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px; /* if linear-gradient, we need to resize it */

  padding: 16px 0 16px 0;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.LastResultCell {
  font-family: Montserrat-Bold;
}

.ResultHistoryTableRow {
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #151522;
  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 20%,
      #e8effa 80%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px; /* if linear-gradient, we need to resize it */
  text-align: center;
}

.DetailsContentContainer {
  padding: 20px 8px;
}

.TrendLineCard {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 32px;
  padding: 32px 32px 4px 32px;
}

.ResultHistoryContainer {
  margin-top: 20px;
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  padding: 32px 16px 52px 32px;
  height: 500px;
}

.ResultHistoryContainer h5 {
  margin-bottom: 24px;
}

.OnboardingStatusText {
  font-family: Montserrat-Bold;
  border-radius: 20px;
  padding: 8px 40px;
  display: inline-block;
}

.Unbillable {
  color: #fc5378;
  background-color: rgba(252, 83, 120, 0.12);
}

.Pending {
  color: #42a1f8;
  background-color: rgba(66, 161, 248, 0.1);
}

.Declined {
  color: #fc5378;
  background-color: rgba(252, 83, 120, 0.12);
}

.Enrolled {
  color: #42a1f8;
  background-color: rgba(66, 248, 193, 0.15);
}

.Verifying_Insurance {
  color: #1e2132c5;
  background-color: rgb(252, 243, 168);
}

.LoaderContainer {
  display: grid;
  place-items: center;
  height: 80%;
}

.HistoryTableContainer {
  width: 99%;
  height: 94%;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 70%;
  margin: 10px auto 0 auto;
  padding: 24px;
  background: #ffffff;
}

.TabSection {
  display: flex;
  color: #828898;

  font-size: 16px;

  margin-bottom: 36px;
}

.TabSection div {
  margin-right: 80px;
  padding: 12px 0;
}

.ActiveTab {
  color: #42a1f8;
  cursor: pointer;
  border-bottom: 5px solid #42a1f8;
}

.InactiveTab {
  padding: 0 8px 8px 8px;
  cursor: pointer;
  color: #828898;
}

.SummarySection {
  margin-bottom: 100px;
}

.PatientDOB {
  margin-left: 8px;
  color: #828898;
  font-style: italic;
}

.DetailBlock {
  margin: 0 auto 96px auto;
}

input:focus {
  outline: none;
  outline-offset: none;
}

.add_patient_input {
  background: #f6f9fd;
  border-radius: 42px;
  border: solid 2px #eee;
  padding: 10px 0px 10px 32px;
  color: #151522;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 24px;
}

.TableFilters {
  display: flex;
  border: 2px solid #e8effa;
  border-radius: 32px;
  padding: 4px 4px;
  font-size: 16px;
}

.FilterLabel {
  color: #828898;
}

.PaginationLeft {
  display: grid;
  justify-items: start;
  align-items: center;
  margin: 12px 12px 36px;
}

.PaginationRight {
  display: grid;
  justify-items: end;
  align-items: center;
  margin: 12px 12px 36px;
}

.PaginationSection {
  grid-template-columns: 1fr 1fr;
  display: grid;
  padding-bottom: 50px;
}
