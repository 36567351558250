.RecordingHeader {
  font-size: 30px;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.RecordingSummary {
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: left;
  border-bottom-width: thin;
  border-bottom-color: black;
  border-bottom-style: solid;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Recordings {
  overflow-y: auto;
  max-height: 75vh;
  width: 100vw;
}

.RecordingTitle {
  font-size: 20px;
  font-weight: bold;
}

/* .recording-summary-content {
  display: inline-block;
} */

.RecordingDate {
  margin-top: 2px;
  padding-right: 20px;
  font-size: 12px;
  justify-self: end;
}

.RecordingStatus {
  font-size: 15px;
}

.RecordingFooter {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  width: 100vw;
  background: black;
  height: 25vh;
  display: grid;
  grid-template-rows: 2fr 1fr;
}

.RecordButton {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: red;
  border-style: solid;
  border-color: black;
  margin: auto;
  place-self: center;
  position: relative;
  box-shadow: 0px 0px 0px 2px white;
}

.RecordLogo {
  place-self: end center;
  margin-bottom: 20px;
}

.RecordingFooterLogo {
  place-self: end center;
  margin-bottom: 10px;
}

.pending {
  background-color: lightgrey;
}

.InactiveTab {
  display: none;
}

.TabButtons {
  position: fixed;
  bottom: 0;
  transform: translate(-50%);
  left: 50%;
  width: 100vw;
  height: 10vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ActiveTab {
  overflow-y: auto;
  max-height: 80vh;
  margin-top: 10px;
  margin-left: 10px;
  width: 100vw;
}

.DetailsHeader {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.DetailsTitle {
  text-align: center;
  margin-top: 5px;
}

.ActiveButton {
  background: darkgrey;
}
