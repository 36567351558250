.flex-box-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin-bottom: 16px;
  max-width: 1200px;
}

.device-summary-container {
  background-color: white;
  margin: 24px;
  margin-top: 0px;
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 300px;
  max-height: 460px;
}

.device-summary-image {
  height: 150px;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 150px;
  margin: 0% auto;
}

.test-type-title {
  text-align: center;
  font-weight: bold;
  margin-top: 24px;
  font-size: 120%;
}

.flex-box {
  display: flex;
  flex-direction: row;
}

.flex-box-partition {
  width: 50%;
}

.sim-device-id-subtitle {
  color: #aaa;
  font-size: 80%;
  margin-top: 0px;
  text-align: center;
  min-height: 20px;
}

.statistic {
  font-size: 100%;
  font-weight: bold;
  text-align: center;
}

.statistic-title {
  font-size: 60%;
  margin-top: -16px;
  color: #888;
  text-align: center;
}
