.status {
  font-size: 100%;
  font-weight: bold;
  text-align: left;
}

.statusContainer {
  background-color: white;
  margin: 24px;
  margin-top: 0px;
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 300px;
  max-height: 460px;
}
