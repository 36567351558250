.Header {
  padding: 20px 60px;
}

.ReportCardMonth {
  grid-area: month;
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 21px;
  color: #121212;

  display: grid;
}

.ReportCardDetail {
  grid-area: detail;
  display: grid;
  place-items: center self-end;
  margin-right: 24px;
}

.ReportCardDetail span {
  margin: 0 8px;
}

.ReportCardDetail a {
  padding: 12px 20px;
  background: #ecf6fe;
  border-radius: 22px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #42a1f8;
  text-decoration: none; /* no underline */
  width: 140px;
}

.ReportCardData {
  grid-area: data;
  display: grid;
  align-items: center;
}

.ReportCardDataContent span {
  margin-right: 8px;
}

.ReportCardDataContent .FirstDataDiv {
  margin-top: 16px;
}

.ReportCardDataContent div {
  margin-top: 24px;
  margin-bottom: 24px;
}

.ReportCardChart {
  grid-area: chart;
  display: grid;
  place-items: center end;
}

.ReportsContainer {
  padding: 40px;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

@media only screen and (max-width: 1024px) {
  .ReportsContainer {
    grid-template-columns: 1fr;
  }
}

.ReportCard {
  height: 300px;
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 32px;
  padding: 20px 20px 0 20px;

  display: grid;
  grid-template-areas:
    "month detail"
    "data chart";

  cursor: pointer;
  background: #ffffff;
}

.SingleReportContainer {
  padding: 16px 20px 0 20px;
  overflow: auto;
}

.RevenueText {
  font-weight: bold;
  font-size: 24px;
  line-height: 21px;

  color: #828898;
}

.EarnedText {
  font-size: 18px;
  line-height: 21px;
  color: #c0c8d5;
}

.PatientsCountText {
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
}

.PatientsCountCategoryText {
  font-size: 14px;
  line-height: 23px;

  color: #828898;
}

.LoaderContainer {
  display: grid;
  place-items: center;
  height: 100%;
}

.ChartDiv {
  margin-right: -20px;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 70%;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;
  background: #ffffff;
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;

  font-size: 16px;
  line-height: 24px;

  color: #9ba0ad;
  text-transform: none;
  border: none;

  padding-left: 20px;
  text-align: center;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;

  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px; /* if linear-gradient, we need to resize it */

  padding: 16px 0 16px 20px;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.ResultHistoryTableRow {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;

  color: #151522;
}

.SingleReportDataHeader {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 400px));
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1024px) {
  .SingleReportDataHeader {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.SingleReportDataPill {
  background: #f2f6fc;
  border-radius: 32px;
  padding: 12px;
  margin-bottom: 8px;
  text-align: center;
}

.attestStatus {
  border-radius: 32px;
  padding: 12px;
  margin-top: 8px;
  text-align: center;
  width: 140px;
}

.attestReviewed {
  background: #e3fef6;
}

.attestNotReviewed {
  background: #fdf3e9;
}

.attestReviewNextMonth {
  background: #ddd3ee;
}

.SingleReportDataValue {
  font-family: "Montserrat-Bold";
  font-size: 28px;
  line-height: 21px;
  color: #121212;
  margin-right: 16px;
}

.SingleReportDataText {
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  color: #828898;
}

.ConfirmationContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  margin: 30px 0;
  padding: 32px;
}

.AttestationTitle {
  margin-top: 40px;
}

.AttestTimeInput {
  display: inline;
  width: 65px;
  margin: 0 0 0 5px;
  border: none;
}

.CPTCodeSection {
  padding-left: 16px;
  font-size: 24px;
  font-family: "Montserrat-Bold";
}
