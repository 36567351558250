.CardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 0 2rem 0 0;
}

.ContentContainer {
  overflow: auto;
  padding: 24px;
}

.RecentTestContainer {
  height: 100%;
}

.ContentContainer p {
  padding: 0 8px;
  margin: 0 auto;
}

.TableCardContainer {
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 1);
  border-radius: 32px;
  height: 100%;
  margin: 0 auto;
  padding: 24px;
  background: #ffffff;
}

.TableFilterContainer {
  display: grid;
  justify-content: end;
}

.TableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 12px;
}

.TableFilters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  border: 2px solid #e8effa;
  border-radius: 32px;
  padding: 4px 4px;
  font-size: 16px;
}

.TableCardContainer h2 {
  margin: 0;
}

.RecentTestTableContainer {
  height: 90%;
  background: #ffffff;
}

.TableRow {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #151522;
  cursor: pointer;
  background: /* gradient can be an image */ linear-gradient(
      to right,
      white 1%,
      #e8effa 2%,
      #e8effa 38%,
      white
    )
    left bottom white no-repeat;
  background-size: 100% 2px;
  /* if linear-gradient, we need to resize it */
  padding: 16px 0 16px 20px;
  text-align: center;
  transition: background-color 0.5s ease-out;
}

.TableRow:hover {
  transition: background-color 0.5s ease-out;
  background: rgb(247, 250, 253);
}

.HeaderRow {
  font-family: "Montserrat-Bold";
  background: linear-gradient(
    90deg,
    #e8effa 41.74%,
    rgba(232, 239, 250, 0) 100.51%
  );
  border: 1.98805px solid #ffffff;
  border-radius: 49.7012px;
  padding: 22px 0 18px 0;
  font-size: 16px;
  line-height: 24px;
  color: #9ba0ad;
  text-transform: none;
  border: none;
  padding-left: 20px;
  text-align: center;
}

.LoaderContainer {
  display: grid;
  place-items: center;
  height: 100%;
}

.HomeCardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 48px;
}

@media only screen and (max-width: 1024px) {
  .HomeCardsContainer {
    grid-template-columns: 1fr;
  }
}

.Card {
  background: #ffffff;
  box-shadow: 16px 16px 70px rgba(216, 222, 228, 0.5);
  border-radius: 16px;
  display: grid;
  min-height: 180px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  border: solid 2px rgba(203, 226, 235, 0.623);
}

.HomeCardTitle {
  font-family: Montserrat-Regular;
}

.HomeCardText {
  font-family: Montserrat-Bold;
  color: #45a3f8;
}

.Modalbody {
  height: 500px;
}

.ReferralAuthorizationModal {
  width: 90vw;
  max-width: none !important;
}

.PatientStatusContentGrid {
  display: grid;
  gap: 8px;
}

.PatientStatusGrid {
  display: grid;
  grid-template-columns: 30px 50px 1fr;
  align-items: center;
}

.CountNumber {
  color: #151522;
  font-family: Montserrat-Bold;
  font-size: 18px;
}

.CountText {
  color: #828898;
}
